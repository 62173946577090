@-webkit-keyframes clockwise {
	100% {
		transform: rotate(1turn);
	}
}

@keyframes clockwise {
	100% {
		transform: rotate(1turn);
	}
}

@-webkit-keyframes counter-clockwise {
	100% {
		transform: rotate(-1turn);
	}
}

@keyframes counter-clockwise {
	100% {
		transform: rotate(-1turn);
	}
}

[type="radio"] {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

input[type="radio"] + label > img {
	border: 1px gray solid;
	border-radius: 10%;
	box-shadow: 2px 2px gray;
}

input[type="radio"]:checked + label > img {
	box-shadow: 0px 0px gray;
}
